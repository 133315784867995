import React from "react";

import ic_cart from "../../assets/icons/cart.svg";
import classes from "./iconbadge.module.css";

const IconBadge = ({ icon = ic_cart, count = 1, onClick = () => {} }) => {
    return (
        <div className={classes.badge} onClick={onClick}>
            <span className={classes.badge__count}>{count}</span>
            <img className={classes.badge__icon} src={icon} alt="cart" />
        </div>
    );
};

export default IconBadge;
