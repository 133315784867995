import React from "react";
import { useContext } from "react";

import LayoutContext from "../../contexts/layout_context";

import ic_close from "../../assets/icons/close.svg";
import ProductCard from "../ProductCard/index";
import classes from "./searchmodal.module.css";
import { useState } from "react";
import useSearch from "../../hooks/useSearch";
import Spinner from "../Spinner";

const SearchModal = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const { searchLoading, searchResult } = useSearch(searchQuery);

    const ctx = useContext(LayoutContext);

    const searchQueryChangeHandler = (e) => {
        setSearchQuery(e.target.value);
    };
    return (
        <div
            className={`${classes.modal_search} ${
                ctx.visibleSearch && classes.visible
            }`}
        >
            <div className="container h-100">
                <div className={classes.wrapper}>
                    <div className={classes.modal_search_header}>
                        <div className={classes.modal_search_header__form}>
                            <input
                                type="text"
                                onChange={searchQueryChangeHandler}
                                value={searchQuery}
                                placeholder="Search for products...."
                            />
                            <button>Search</button>
                        </div>
                        <div className={classes.modal_search_header__close}>
                            <img
                                src={ic_close}
                                alt="close"
                                onClick={ctx.toggleSearch}
                            />
                        </div>
                    </div>
                    {searchLoading && (
                        <div className={classes.modal_search__loader}>
                            <Spinner />
                        </div>
                    )}
                    {!searchLoading && searchQuery && (
                        <h6 className={classes.modal_search_nos_found}>
                            Found {searchResult.length} items:
                        </h6>
                    )}
                    {!searchLoading && searchResult.length > 0 && (
                        <div className={classes.modal_search_results_wrapper}>
                            <div className={classes.modal_search__results}>
                                {searchResult.map((item, index) => {
                                    return (
                                        <ProductCard
                                            product={item}
                                            key={index}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchModal;
