import React from "react";
import { useContext } from "react";
import CartItem from "../../components/CartItem";
import CartSummary from "../../components/CartSummary";
import CartContext from "../../contexts/cart_context";
import img_cart_empty from "../../assets/images/empty-cart.svg";
import classes from "./cart.module.css";

const Cart = () => {
    const cartContext = useContext(CartContext);
    return (
        <div className="container">
            <div className={classes.cart__wrapper}>
                <div className={classes.cart__cart_wrapper}>
                    {cartContext.getCartItemsCount() > 0 &&
                        cartContext.getCartHandler().map((cartItem, index) => {
                            return <CartItem cartItem={cartItem} key={index} />;
                        })}
                    {cartContext.getCartItemsCount() === 0 && (
                        <div className={classes.cart__cart_empty}>
                            <img src={img_cart_empty} alt="" />
                        </div>
                    )}
                </div>
                <div className={classes.cart__summary_wrapper}>
                    <CartSummary />
                </div>
            </div>
        </div>
    );
};

export default Cart;
