import React from "react";
import Header from "../Header";
import { Outlet } from "react-router-dom";
import SearchModal from "../SearchModal";
import CustomModal from "../CustomModal";

const Wrapper = () => {
    return (
        <>
            <Header />
            <Outlet />
            <SearchModal />
            <CustomModal />
        </>
    );
};

export default Wrapper;
