import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import classes from "./productimageslider.module.css";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { IMAGE_URL } from "../../utils/constants";

const ProductImageSlider = ({ productImages = [] }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
        <>
            <Swiper
                navigation={true}
                className={classes.swiper__product_images}
                spaceBetween={10}
                thumbs={{
                    swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed
                            ? thumbsSwiper
                            : null,
                }}
                modules={[FreeMode, Navigation, Thumbs]}
            >
                {productImages.length > 0 &&
                    productImages.map((image, index) => {
                        return (
                            <SwiperSlide
                                key={index}
                                className={classes.swiper__product_image_slide}
                            >
                                <img
                                    src={`${IMAGE_URL}/${image}`}
                                    alt="product"
                                />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                navigation={true}
                className={classes.swiper__product_thumbs}
                slidesPerView={4}
                spaceBetween={10}
                modules={[FreeMode, Navigation, Thumbs]}
            >
                {productImages.length > 1 &&
                    productImages.map((image, index) => {
                        return (
                            <SwiperSlide
                                key={index}
                                className={classes.swiper__product_thumb_slide}
                            >
                                <div className={classes.swiper__product_thumb}>
                                    <img
                                        src={`${IMAGE_URL}/${image}`}
                                        alt="product"
                                    />
                                </div>
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </>
    );
};

export default ProductImageSlider;
