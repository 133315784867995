import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import ProductImageSlider from "../../components/ProductImageSlider";
import CartContext from "../../contexts/cart_context";
import LayoutContext from "../../contexts/layout_context";
import classes from "./productdetail.module.css";
const ProductDetail = () => {
    const { state } = useLocation();
    const cartContext = useContext(CartContext);
    const layoutContext = useContext(LayoutContext);
    const cartData = cartContext.getCartItemHandler(state._id);
    const [qty, setQty] = useState(cartData ? cartData.qty : 1);
    const addtoCartHandler = () => {
        if (isNaN(parseFloat(qty)) || parseFloat(qty) < 1) {
            setQty(1);
        } else {
            setQty(parseFloat(qty));
        }
        cartContext.addtoCartHandler(state, qty);
        layoutContext.setShowNotification((prevState) => {
            return {
                ...prevState,
                visible: true,
                message: "Product added to your cart",
                type: "notification",
                status: "success",
            };
        });
    };
    const qtyChangeHandler = (e) => {
        setQty(e.target.value);
    };
    return (
        <div className="container">
            <div className={classes.product_detail__wrapper}>
                <div className={classes.product_detail__slider_wrapper}>
                    <ProductImageSlider productImages={state.imagekeys || []} />
                </div>

                <div
                    className={classes.product_detail__info_wrapper}
                    style={{ flex: 1 }}
                >
                    <h1
                        style={{
                            fontSize: "25px",
                            color: "var(--clr-black)",
                            fontWeight: "400",
                            textTransform: "capitalize",
                            marginBottom: "25px",
                        }}
                    >
                        {state.name.toLowerCase()}
                    </h1>
                    <div
                        className={classes.product_detail__details_wrapper}
                        style={{ marginBottom: "20px" }}
                    >
                        <p
                            className={classes.product_detail__details_lbl}
                            style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                letterSpacing: "1.2px",
                                marginBottom: "8px",
                            }}
                        >
                            DESCRIPTION:
                        </p>
                        <p style={{ fontFamily: "monospace" }}>
                            {state.description}
                        </p>
                    </div>
                    <h1
                        style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            marginBottom: "30px",
                        }}
                    >
                        AED {state.retailprice}
                    </h1>
                    <div
                        className={classes.product_detail__add_to_cart_wrapper}
                        style={{ display: "flex", columnGap: "10px" }}
                    >
                        <input
                            type="number"
                            value={qty}
                            onChange={qtyChangeHandler}
                            style={{
                                width: "4em",
                                padding: "10px 4px",
                                border: "1px solid var(--clr-black)",
                                outline: "none",
                                textAlign: "center",
                            }}
                        />
                        <button
                            style={{
                                backgroundColor: "var(--clr-rose)",
                                color: "var(--clr-white)",
                                padding: "10px 20px",
                                cursor: "pointer",
                                fontSize: "13px",
                                fontWeight: "600",
                                border: "none",
                            }}
                            onClick={addtoCartHandler}
                        >
                            ADD TO BASKET
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;
