import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CartContext from "../../contexts/cart_context";
import LayoutContext from "../../contexts/layout_context";
import { IMAGE_URL } from "../../utils/constants";
import classes from "./productcard.module.css";

const ProductCard = ({ product }) => {
    const cartContext = useContext(CartContext);
    const layoutContext = useContext(LayoutContext);
    const navigator = useNavigate();
    const viewClickHandler = () => {
        if (layoutContext.visibleSearch) {
            layoutContext.toggleSearch();
        }
        navigator(`/products/${product._id}`, { state: product });
    };
    const addToCartHandler = () => {
        cartContext.addtoCartHandler(product);
        layoutContext.setShowNotification((prevState) => {
            return {
                ...prevState,
                visible: true,
                message: "Product added to your cart",
                type: "notification",
                status: "success",
            };
        });
    };
    return (
        <div className={classes.product_card}>
            <div className={classes.wrapper}>
                <div className={classes.product_card__product_image}>
                    <img
                        src={`${IMAGE_URL}/${product.imagekeys[0]}`}
                        alt="product"
                        loading="lazy"
                    />
                </div>
                <p className={classes.product_card__name}>{product.name}</p>
                <p className={classes.product_card__price}>
                    AED {product.retailprice}
                </p>
                <div className={classes.product_card__actions}>
                    <button
                        className={classes.product_card__add_to_cart}
                        onClick={addToCartHandler}
                    ></button>

                    <button
                        className={classes.product_card__view_more}
                        onClick={viewClickHandler}
                    ></button>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
