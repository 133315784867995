import React from "react";
import { useParams } from "react-router-dom";
import classes from "./orderdetail.module.css";

const OrderDetail = () => {
    const { orderId } = useParams();
    return (
        <div className="container">
            <div className={classes.order_success__wrapper}>
                <div className={classes.order_success__box}>
                    <svg
                        className={classes.checkmark}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                    >
                        <circle
                            className={classes.checkmark__circle}
                            cx="26"
                            cy="26"
                            r="25"
                            fill="none"
                        />
                        <path
                            className={classes.checkmark__check}
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                    </svg>
                    <p className={classes.successMessage}>
                        Your order placed successfully
                    </p>
                    <p className={classes.orderNumber}>
                        Order Number is : <span>{orderId}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OrderDetail;
