import axios from "axios";
import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import CategryCard from "../../components/CategoryCard";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/constants";
import classes from "./home.module.css";
const Home = () => {
    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(true);

    const getCategoriesHandler = useCallback(async () => {
        const config = {
            url: `${BASE_URL}/categories`,
        };
        try {
            const response = await axios(config);
            setCategoriesLoading(false);
            setCategories(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getCategoriesHandler();
    }, [getCategoriesHandler]);
    return (
        <div className={classes.category_wrapper}>
            <div className="container">
                {categoriesLoading && (
                    <div className={classes.categories__loader}>
                        <Spinner />
                    </div>
                )}
                <div className={classes.wrapper}>
                    {!categoriesLoading &&
                        categories.length > 0 &&
                        categories.map((item, index) => {
                            return <CategryCard category={item} key={index} />;
                        })}
                </div>
            </div>
        </div>
    );
};

export default Home;
