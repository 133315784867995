import React from "react";
import { IMAGE_URL } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import classes from "./categorycard.module.css";

const CategryCard = ({ category }) => {
    const navigate = useNavigate();
    const categoryClickHandler = () => {
        navigate(`/categories/${category._id}`);
    };
    return (
        <div
            className={classes.category_card}
            style={{
                backgroundImage: `url(${IMAGE_URL}/${category.imagekey})`,
            }}
            onClick={categoryClickHandler}
        >
            <div className={classes.category_card_overlay}>
                <div className={classes.category_card__inner}>
                    <p>{category.name}</p>
                </div>
            </div>
        </div>
    );
};

export default CategryCard;
